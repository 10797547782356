import { ValidationError } from 'yup';

interface Error {
  [key: string]: string;
}

export default function getValidationErrors(error: ValidationError): Error {
  const validationErrors: Error = {};

  error.inner.forEach(({ path, message }) => {
    validationErrors[path] = message;
  });

  return validationErrors;
}
