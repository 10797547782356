import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('E-mail obrigatório')
    .email('Digite um e-mail válido'),
  oldPassword: Yup.string(),
  password: Yup.string().when('oldPassword', {
    is: val => !!val.length,
    then: Yup.string().min(6, 'No mínimo 6 digitos'),
    otherwise: Yup.string(),
  }),
  passwordConfirmation: Yup.string()
    .when('oldPassword', {
      is: val => !!val.length,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
});
